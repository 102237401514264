/* You can add global styles to this file, and also import other style files */

@import "../node_modules/toastr/build/toastr.min.css";

$font-family-sans-serif: Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@import "../node_modules/element.ui/modules/all.scss";
$fa-font-path: "../node_modules/font-awesome/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";
/* ELEMENT STYLES */
body {
    position: relative;
    height: 100%; 
    color:$color-grey-70;
}

strong {
    color: $color-grey-90;
}

dd, dt {
    line-height: 1.25;
}

dt {
    font-weight: 500;
}
/* NEED TO OVERWRITE ELEMENT UI */
h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
}

.mcui-btn svg, .icon-display svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}


/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* BOOTSTRAP OVERIDES */
.navbar {
    align-items: initial !important;
}
a.navbar-brand {
    vertical-align: top !important;
}

#main-navigation .nav-item a.nav-link {
    color: $color-grey-70;
}
a.nav-link, a.navbar-brand {
    color: $color-mayo-50;
    font-size: 24px;
}

a.navbar-brand span {
    font-size: 2.5rem;
    text-transform: uppercase;
}

a.navbar-brand span.application-title {
    font-size: 1.5rem;
}

a.navbar-brand span.application-secondary-title  {
    font-size: 1.5rem;
}

a.navbar-brand img {
    height: 120px;
}

.btn {
    text-transform: uppercase;
}

.btn-primary {
    color: #fff;
    background-color: $color-mayo-50;
    border-color: $color-mayo-50;
}

.bg-light {
    background-color: $color-mayo-10 !important;
}

.bg-dark {
    background-color: $color-grey-90 !important;
}

.list-group-item.active {
    background-color: $color-mayo-50;
    border-color: $color-mayo-50;
}

.mayo .navbar-toggler-icon {
    color: $color-mayo-50;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,61,165, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.mayo.navbar-toggler {
    border-color: $color-mayo-50;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: $color-green-90;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    background-color: $color-red-90;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $color-mayo-50;
    border-color: $color-mayo-50;
}

.card {
    border-color: $color-mayo-40;
}

.card-img-container {
    background-color: $color-mayo-50;
    border: 1px solid $color-mayo-50;
    flex: 1 1 auto;
}

a.card-img-container, a.card-img-container:hover {
    text-decoration: none;
}

.carousel-wrapper {
    border: 1px solid $color-grey-50;
    border-radius: .25em;
}

.carousel-footer {
    background-color: $color-grey-50;
    color: #FFF;
}

.carousel-caption h5 {
    font-size: 6vw;
    text-shadow: 5px 5px 15px $color-grey-90;
}

.carousel-caption-top {

    top: 100px;
    bottom: auto !important;
}

.carousel-caption-top h3, .carousel-caption-top h3 a {
    font-size: 2vw;
    color: #FFF;
    text-shadow: 5px 5px 15px $color-grey-90;
}

.carousel-footer a {
    color: #FFF;
}

.card-img-topx {
    height: 200px;
}

svg.card-img-top {
    fill: #FFF;
    background-color: $color-mayo-50;
    padding-bottom: 10px;
    padding-top:10px;
}

svg.page-title-icon {
    fill: #FFF;
    background-color: $color-mayo-50;
    height: 25px;
    width: 25px;
}


svg.social-icons {
    fill: $color-mayo-20;
    background-color: $color-grey-90;
    height: 25px;
    width: 25px;
}

.card-img-append {
    color: #FFF;
    background-color: $color-mayo-50;
    padding-bottom: 5px;
    padding-top: 5px;
}

.card-header {
    color: #FFF;
    background-color: $color-mayo-50;
    border-bottom: 1px $color-mayo-50;
    font-size: 20px;
}


.card-footer {
    background-color: $color-mayo-20;
}

[data-toggle="collapse"] .fa {
    color:#FFF;
}

[data-toggle="collapse"] .fa:before {
    content: "\f077";
}

[data-toggle="collapse"].collapsed .fa:before {
    content: "\f078";
}

/* UI ELEMENT OVVERIDES */
a.mcui-btn {
    display: inline-block;
}

/* LAYOUT (HEADER FOOTER) */
.header {
    background-color: white !important;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.footer {
    min-height: 60px;
    background-color: $color-grey-90;
    color: #FFF;
}

.footer a {
    color: #FFF;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}
/* CUSTOM CLASSES HERE */
.columns-1 {
    column-count:1;
}
.columns-3 {
    column-count: 3;
}
.flex-container {
    display: flex;
    flex-direction: row;
}

.card a:hover {
    text-decoration:none;
}

a.application-title {
    line-height: 2rem;
}

a.application-title span {
    font-size: 3rem;
    line-height: 2rem;
    font-family: "Helvetica", sans-serif;

}

img.lighten {
    opacity: 0.5;
}

a.application-secondary-title span {
    font-size: 2.5rem;
    text-transform:none;
    font-family: "Helvetica", sans-serif;
}

a.nav-secondary-link {
    color: $color-grey-90;
    font-size: 20px;
}
.table .thead-mayo th {
    color: #fff;
    background-color: $color-mayo-50;
    border-color: $color-mayo-50;
}

.back-to-top {
    background: none;
    margin: 0;
    position: fixed;
    bottom: 0;
    width: 70px;
    height: 70px;
    z-index: 100;
    display: none;
    text-decoration: none;
    opacity: .7;
}

.back-to-top i {
    font-size: 60px;
    color: $color-mayo-40;
}

.affix {

        position: fixed;
        z-index: 1030;
        top: 0;
}

@import "print.scss";
@import "responsive.scss";

