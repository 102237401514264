﻿/* PRINT */
@media print {
    @page {
        margin: 3cm;
    }

    .bg-light {
        background-color: #FFF !important;
    }

    a.printable-link {
        font-weight: 700;
    }

    a.printable-link:after {
        content: " [" attr(href) "] ";
        font-weight: normal;
        text-decoration: none;
    }

    a.navbar-brand, a.printable-link {
        text-decoration: none;
        color: #212529;
    }

    a.navbar-brand span.application-title {
        font-size: 5rem;
        margin-left: 1em;
    }

    .card-img-top {
        display: none;
    }

    div {
        box-shadow: none !important;
    }

    svg {
        display: none;
    }

    .card-header {
        margin-bottom: 1px !important;
        color: #212529;

    }

    .card-header {
        color: #212529;
        background-color: #FFF;
        border: none !important;
    }

    .card-header span.pull-left {
        color: #212529;
    }

    .card-header span.pull-left .ml {
        margin-left: 0rem !important;
    }

    .card {
        border-style: none;
        box-shadow: none !important;
        margin-bottom: 5px !important;
    }

    .tab-pane {
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
        page-break-inside: avoid;
    }

    .footer {
        color: #212529;
    }

    .bg-dark {
        background-color: #FFF !important;
    }
}
