﻿/* MEDIA QUERIES */



/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    /* Styles */
    .page-title {
        font-size: 1em;
    }

    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    article.p-5 {
        padding: 0rem !important;
    }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
    /* Styles */

}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }

    article.p-5 {
        padding: 0rem !important;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    /* Styles */

}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
}

/* iPad 3 --------------------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}
/*  iPad Pro 9.7"----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
/*iPad Pro 10.5"*/
/* Portrait */
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
/*ipad Pro 12.9"*/
/* Portrait */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}



/* Desktops and laptops ----------- */
@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-collapse .application-title, .navbar-expand-xl .navbar-collapse .display-screen {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-brand span.application-title {
        display: none;
    }

    .navbar-expand-xl .navbar-collapse .display-mobile {
        display: none;
    }

    main {
        min-height: 700px;
    }
}

@media (max-width: 1200px) {
    .navbar-expand-xl .navbar-collapse .application-title, .navbar-expand-xl .navbar-collapse .display-screen {
        display: none;
    }

    .navbar-brand span.application-title {
        display: inline-flex;
    }

    .navbar-brand span.application-secondary-title {
        display: inline-flex;
    }

    a.nav-link {
        font-size: 16px;
    }

    .navbar-expand-xl .navbar-collapse .display-mobile {
        display: flex !important;
        flex-basis: auto;
    }

    main {
        min-height: 700px;
    }
}


@media only screen and (min-width : 1224px) {
    /* Styles */
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
    /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: .75rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}
